const LIRServicesList = [
  {
    name: 'RIPE ASN',
    learn_more_url: '/articles/what-is-an-asn',
    points: [
      'RIPE Autonomous System Number',
      'Fast application',
      '£45 GBP one-off'      
    ],
    description: 'RIPE Autonomous System Number (fast application)',
    cta: 'Order',
    sku: 'RIPE-ASN',
    cost: 4500,
    period: null
  }, {
    name: 'RIPE PA IPv6 /48',
    learn_more_url: '/articles/ipv6',
    points: [
      'RIPE Aggregate IPv6 range',
      '65,536 /64s',
      '£30 GBP annually'
    ],
    description: 'RIPE Aggregate IPv6 range /48 (65,536 /64s)',
    cta: 'Order',
    sku: 'RIPE-IPv6-PA-48',
    cost: 3000,
    period: 'yr'
  }, {
    name: 'RIPE PA IPv6 /44',
    learn_more_url: '/articles/ipv6',
    points: [
      'RIPE Aggregate IPv6 range',
      '1,048,576 /64s',
      '£40 GBP annually'
    ],
    description: 'RIPE Aggregate IPv6 range /44 (1,048,576 /64s)',
    cta: 'Order',
    sku: 'RIPE-IPv6-PA-44',
    cost: 4000,
    period: 'yr'
  }, {
    name: 'RIPE PI IPv6 /44',
    learn_more_url: '/articles/ipv6',
    points: [
      'RIPE Independent IPv6 range',
      '1,048,576 /64s',
      '£70 GBP annually'
    ],
    description: 'RIPE Independent IPv6 range /44 (1,048,576 /64s)',
    cta: 'Order',
    sku: 'RIPE-IPv6-PI-48',
    cost: 7000,
    period: 'yr'
  }, {
    name: 'RIPE IPv4 /24',
    learn_more_url: '/articles/ipv4',
    points: [
      'RIPE Assigned IPv4 range',
      '254 usable hosts',
      'Pricing on enquiry'
    ],
    cta: 'Enquire',
    sku: null,
    cost: null,
    period: null
  }, {
    name: 'RIPE ASN Transfer',
    learn_more_url: '/articles/asn',
    points: [
      'RIPE Autonomous System Number transfer',
      'Transfer your ASN from another LIR to Inferno',
      '£35 GBP one-off'      
    ],
    description: 'RIPE Autonomous System Number transfer (fast transfer)',
    cta: 'Order',
    sku: 'RIPE-ASN-TRANSFER',
    cost: 3500,
    period: null
  }, {
    name: 'RIPE PI Transfer',
    learn_more_url: '/articles/pi-vs-pa',
    points: [
      'RIPE Independent resource transfer',
      'Transfer your PI resources from another LIR to Inferno',
      'Pricing on enquiry'     
    ],
    description: 'RIPE Independent resource transfer (fast transfer)',
    cta: 'Enquire',
    sku: 'RIPE-PI-TRANSFER',
    cost: null,
    period: null
  }
]

export {
  LIRServicesList
}