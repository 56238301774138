/** @jsx jsx */
import * as React from "react"
import { jsx, css } from "@emotion/react"
import { SEO } from "../components/seo"

import { useSelector, useDispatch } from 'react-redux'
import { useCallback, useState } from 'react'

import Page from "../layouts/page"
import { Link } from "gatsby"

//import Countries from "./countries.json"

import { LIRServicesList } from '../data/products'
//import { navigate } from "gatsby"

import { Formik, Form, Field } from 'formik'

const styles = css`
.uk-alert.addipspace {
  display: flex;
  margin-top: 8px;

  .message {
    .order-buttons {
      margin-top: 8px;
    }
  }

  button {
    margin-right: 8px;
  }
}

.item-name {
  font-weight: 500;
}
.item-sub {
  font-size: 12px;
  font-weight: lighter;
}

.checkout-split {
  display: flex;

  .checkout-split-item {
    width: 50%;
    padding: 4px;
  }

  .checkout-split-info {
    input[type="checkbox"] {
      margin-right: 4px;
    }

    label.tos-label {
      font-size: 14px;
      font-weight: light;
    }
  }

  .checkout-split-items {
    padding-left: 24px;

    span.uk-icon {
      width: 24px;
    }
  }
}

option.default {
  color: #666;
}

.uk-tab li a {
  text-decoration: none;
}

.table-control-hidden {
  display: none;
}

@media (max-width: 1000px) {
  .checkout-split {
    flex-flow: column;
    flex-direction: column-reverse;
    
    .checkout-split-item {
      width: 100%;
      padding: 0;
    }
  }
}
`

const OrderPage = () => {
  const basket = useSelector(state => state.basket)
  const dispatch = useDispatch()

  const [formSent, setFormSent] = useState(false)

  const addSKU = useCallback(
    (sku) => {
      dispatch({
        type: 'ADD_SKU',
        sku,
      })
    }, [])

  const removeSKU = useCallback(
    (sku) => {
      dispatch({
        type: 'REM_SKU',
        sku,
      })
    }, [])

  const [newCustomer, setNewCustomer] = useState(true)

  const updateCustomerType = useCallback(
    (type) => {
      setNewCustomer(type)
    }, [])

  const asnProducts = Object.keys(basket).some(item => item === 'RIPE-ASN')
  const ipProducts = Object.keys(basket).some(item => item.includes('IPv6'))

  const noProducts = Object.keys(basket).length === 0
  const promptForIPProduct = asnProducts && !ipProducts
  const promptForASNProduct = !asnProducts && ipProducts

  const asnProductsCount = (basket['RIPE-ASN'] ? basket['RIPE-ASN'] : 0)

  return <Page>
    <div css={styles}>
      <div className="contain page-body">
        <h1>New Order</h1>

        <div className="">

          <div className="checkout-split">
            <div className="checkout-split-item checkout-split-info">

              <ul className="uk-tab">
                <li className={newCustomer ? 'uk-active' : ''}><a role="button" tabIndex={0} onClick={() => updateCustomerType(true)}>New Customer</a></li>
                <li className={!newCustomer ? 'uk-active' : ''}><a role="button" tabIndex={0} onClick={() => updateCustomerType(false)}>Existing Customer</a></li>
              </ul>

              <div className={newCustomer ? '' : 'hidden'}>

                <Formik
                  initialValues={{
                    first_name: '',
                    last_name: '',
                    entity_type: 'company',
                    email: '',
                    company_name: '',
                    company_number: '',
                    company_vat: '',
                    address_line1: '',
                    address_line2: '',
                    address_city: '',
                    address_region: '',
                    address_country: '',
                    address_postcode: '',
                    agree: false
                  }}
                  validate={values => {
                    const errors = {}

                    if (!values.first_name || !values.last_name) {
                      errors.name = 'First and last name are required'
                    }

                    if (!values.email) {
                      errors.email = 'Email address is required';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                      errors.email = 'A valid email address is required'
                    }

                    if (
                      values.entity_type !== 'company' &&
                      values.entity_type !== 'soletrader' &&
                      values.entity_type !== 'individual'
                    ) {
                      errors.entity_type = 'Invalid entity type'
                    }

                    if (values.entity_type === 'company') {
                      if (!values.company_name) {
                        errors.company_name = 'Company name is required'
                      }
                      /*if(!values.company_number) {
                        errors.company_number = 'Company number is required'
                      }*/
                    }

                    if (!values.address_line1) {
                      errors.address_line1 = 'Address line 1 is required'
                    }
                    if (!values.address_city) {
                      errors.address_city = 'Address city is required'
                    }
                    if (!values.address_region) {
                      errors.address_region = 'Address region is required'
                    }
                    if (!values.address_postcode) {
                      errors.address_country = 'Address postcode is required'
                    }
                    if (!values.address_country) {
                      errors.address_country = 'Address country is required'
                    }

                    if (!values.agree) {
                      errors.agree = 'You must agree to the terms'
                    }

                    return errors
                  }}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={(values, { setSubmitting }) => {
                    values.skus = basket

                    fetch('/api/order', {
                      method: 'PUT',
                      headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify(values)
                    })
                      .then((resp) => {
                        setSubmitting(false)
                        if(resp.status !== 200) {
                          return alert('Sorry, your order was unable to be sent at this time due to an error.\n' + resp.status + '\n\nPlease try again later or send your order to sales@infernocomms.com.\n\nApologies for the inconvience.')
                        }

                        setFormSent(true)
                      })
                      .catch((err) => {
                        alert(err)
                        setSubmitting(false)
                      })
                  }}
                >

                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <Form className={'uk-form-stacked' + (!formSent ? '' : ' hidden')}>

                      <div className="uk-margin uk-grid uk-grid-small">
                        <div className="uk-flex-1">
                          <label className="uk-form-label" htmlFor="first_name">First name</label>

                          <Field name="first_name" id="first_name" className="uk-input" type="text" placeholder="John" />
                        </div>
                        <div className="uk-flex-1">
                          <label className="uk-form-label" htmlFor="last_name">Last name</label>
                          <Field name="last_name" id="last_name" className="uk-input" type="text" placeholder="Doe" />
                        </div>
                      </div>

                      <div className="uk-margin uk-grid uk-grid-small">
                        <div className="uk-flex-1">
                          <label className="uk-form-label" htmlFor="entity_type">Entity type</label>
                          <div className="uk-flex-1">
                            <Field as="select" name="entity_type" id="entity_type" className="uk-select">
                              <option value="company">Registered Company</option>
                              <option value="soletrader">Sole Trader</option>
                              <option value="individual">Individual</option>
                            </Field>
                          </div>
                        </div>
                        <div className="uk-flex-1">
                          <label className="uk-form-label" htmlFor="email">Email</label>
                          <div className="uk-flex-1">
                            <Field name="email" id="email" className="uk-input" type="email" placeholder={values.entity_type === 'company' ? 'john.doe@company.org' : 'john.doe@personal.me'} />
                          </div>
                        </div>
                      </div>

                      {values.entity_type === 'soletrader' && (
                        <>
                          <div className="uk-margin uk-grid uk-grid-small">
                            <div className="uk-flex-1">
                              <label className="uk-form-label" htmlFor="company_name">Trading name</label>
                              <Field name="company_name" id="company_name" className="uk-input" type="text" placeholder="Sole's Trading" />
                            </div>
                          </div>
                        </>
                      )}
                      {values.entity_type === 'company' && (
                        <>
                          <div className="uk-margin uk-grid uk-grid-small">
                            <div className="uk-flex-1">
                              <label className="uk-form-label" htmlFor="company_name">Company name</label>
                              <Field name="company_name" id="company_name" className="uk-input" type="text" placeholder="Company Ltd" />
                            </div>
                          </div>
                          <div className="uk-margin uk-grid uk-grid-small">
                            <div className="uk-flex-1">
                              <label className="uk-form-label" htmlFor="company_number">Company number</label>
                              <Field name="company_number" id="company_number" className="uk-input" type="text" placeholder="12345678" />
                            </div>
                            <div className="uk-flex-1">
                              <label className="uk-form-label" htmlFor="company_vat">VAT number (optional)</label>
                              <Field name="company_vat" id="company_vat" className="uk-input" type="text" placeholder="XX123456789" />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="uk-margin uk-grid uk-grid-small">
                        <div className="uk-flex-1">
                          <label className="uk-form-label" htmlFor="address_line1">Billing address</label>
                          <Field name="address_line1" id="address_line1" className="uk-input" type="text" placeholder="Line 1" />
                        </div>
                      </div>

                      <div className="uk-margin uk-grid uk-grid-small">
                        <div className="uk-flex-1">
                          <Field name="address_line2" className="uk-input" type="text" placeholder="Line 2" />
                        </div>
                      </div>

                      <div className="uk-margin uk-grid uk-grid-small">
                        <div className="uk-flex-1">
                          <Field name="address_city" className="uk-input" type="text" placeholder="City" />
                        </div>
                        <div className="uk-flex-1">
                          <Field name="address_region" className="uk-input" type="text" placeholder="State/Provience/Region" />
                        </div>
                      </div>

                      <div className="uk-margin uk-grid uk-grid-small">
                        <div className="uk-flex-1">
                          <Field name="address_country" className="uk-input" type="text" placeholder="Country" />
                        </div>
                        <div className="uk-flex-1">
                          <Field name="address_postcode" className="uk-input" type="text" placeholder="Postcode/ZIP" />
                        </div>
                      </div>

                      <div className="uk-margin uk-grid uk-grid-small">
                        <div className="uk-flex-1">
                          <Field name="agree" id="agree" className="uk-checkbox" type="checkbox" /> <label className="tos-label" htmlFor="agree">I agree to the <a href="/legal/general-terms" target="_blank">general terms of service</a> and <a href="/legal/privacy" target="_blank">privacy policy</a>.</label>
                        </div>
                      </div>

                      <div className="uk-margin uk-grid uk-grid-small">
                        <div className="uk-flex-1">
                          <button
                            className="uk-button uk-button-primary"
                            type="submit"
                            disabled={isSubmitting || formSent}
                          >Place Order</button>
                        </div>
                      </div>

                      {Object.keys(errors).length !== 0 && (
                        <div className="uk-alert uk-alert-error">
                          <div className="icon">
                            <span uk-icon="warning"></span>
                          </div>
                          <div className="message">
                            <ul>
                              {Object.values(errors).map((err) =>
                                <li key={err}>{err}</li>
                              )}
                            </ul>
                          </div>
                        </div>
                      )}

                    </Form>

                  )}
                </Formik>

                {formSent && (
                  <div className="uk-alert uk-alert-success">
                    <div className="icon">
                      <span uk-icon="check"></span>
                    </div>
                    <div className="message">
                      <div><b>Thank you for your order!</b></div>
                      <div>We will be in touch soon to begin customer onboarding, please keep an eye out in your inbox. If you have any other questions in the meantime, please feel free to <Link to="/contact-us">get in touch</Link>.</div>
                    </div>
                  </div>
                )}
              </div>
              <div className={!newCustomer ? '' : 'hidden'}>
                <div className="uk-placeholder">
                  <p>Existing customers should place orders through the <a href="https://portal.infernocomms.com">customer portal</a>.</p>
                </div>
              </div>
            </div>
            <div className="checkout-split-item checkout-split-items">

              <table className="uk-table uk-table-divider uk-table-middle">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Cost</th>
                    <th className="table-control-hidden">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(basket).map(([sku, quantity]) => {
                    const item = LIRServicesList.find(srv => srv.sku === sku)
                    return <tr key={item.sku}>
                      <td>
                        <div className="item-name">{item.name}</div>
                        <div className="item-sub">{item.sku}</div>
                      </td>
                      <td>
                        {quantity}
                      </td>
                      <td>
                        <div className="item-name">£{((item.cost * quantity) / 100).toFixed(2).toLocaleString()}</div>
                        {item.period === 'mo' && (
                          <div className="item-sub">monthly</div>
                        )}
                        {item.period === 'yr' && (
                          <div className="item-sub">annually</div>
                        )}
                      </td>
                      <td>
                        <button
                          className="uk-button uk-button-default uk-button-small"
                          onClick={() => removeSKU(sku)}
                          disabled={formSent}
                        >
                          <span uk-icon="minus-circle"></span>
                        </button>
                      </td>
                    </tr>
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      £{(Object.entries(basket).map(([sku, quantity]) => {
                        const item = LIRServicesList.find(srv => srv.sku === sku)
                        return item.cost * quantity
                      }).reduce((a, b) => a + b, 0) / 100).toFixed(2).toLocaleString()}
                    </td>
                    <td>due today</td>
                  </tr>
                </tbody>
              </table>

              {promptForIPProduct && (
                <div className="uk-alert uk-alert-warning addipspace">
                  <div className="icon">
                    <span uk-icon="info"></span>
                  </div>
                  <div className="message">
                    If you are ordering an ASN, please make sure you have IP space to announce from it in advance, as this is necessary for your assignment request.<br />
                    If you don't have any, you can add some to your order:
                    <div className="order-buttons">
                      <button className="uk-button uk-button-default" onClick={() => addSKU('RIPE-IPv6-PA-48')}>Add IPv6 PA /48</button>
                      <button className="uk-button uk-button-default" onClick={() => addSKU('RIPE-IPv6-PA-44')}>Add IPv6 PA /44</button>
                    </div>
                  </div>
                </div>
              )}

              {promptForASNProduct && (
                <div className="uk-alert uk-alert-warning addipspace">
                  <div className="icon">
                    <span uk-icon="info"></span>
                  </div>
                  <div className="message">
                    If you are ordering IPv6 address space, please make sure you have an ASN to announce it from. If you don't have one, you can add one to your order:
                    <div className="order-buttons">
                      <button className="uk-button uk-button-default" onClick={() => addSKU('RIPE-ASN')}>Add ASN</button>
                    </div>
                  </div>
                </div>
              )}

              {asnProductsCount > 1 && (
                <div className="uk-alert uk-alert-warning addipspace">
                  <div className="icon">
                    <span uk-icon="info"></span>
                  </div>
                  <div className="message">
                    You have more than one ASN in your order, you may want to remove it if this is not what you want.
                  </div>
                </div>
              )}

              {noProducts && (
                <div className="uk-alert uk-alert-info addipspace">
                  <div className="icon">
                    <span uk-icon="info"></span>
                  </div>
                  <div className="message">
                    You don't have any products in your order at the moment. If you wish to register without purchasing a product at this time, you can still do so.
                  </div>
                </div>
              )}

              <div className="uk-alert uk-alert-info addipspace">
                <div className="icon">
                  <span uk-icon="question"></span>
                </div>
                <div className="message">
                  If you have any questions before ordering, please feel free to get in <Link to="/contact-us">touch with us by email or chat</Link>.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
}

export const Head = () => (
  <SEO
    title="Order"
    path="/order"
  />
)

export default OrderPage
